<template>
    <b-row style="max-width:375px" class="centered-container">
        <b-row class="mt-4">
            <b-col class="header-container mb-3">
                <h2 class="centered-header">Update your password</h2>
                <span class="span-item">
                    Please check your email for the OTP
                </span>
            </b-col>
        </b-row>
        <b-row class="centered-content ">
            <b-form class="customized-form w-100">
                <ValidationObserver ref="updatePassword">
                    <div class="otp-input1">
                        <input v-for="(digit, index) in otpDigits" :key="index" :id="index" required
                            v-model="otpDigits[index]" type="tel" pattern="^\d{1,9}$" ref="OTPPassRef" autocomplete="off"
                            @keyup="handleKeyDown($event, index)" class="otp-box1" maxlength="1" :tabindex="index"
                            :style="otpIsRequired ? `border: 2px solid #ff4b4b !important;` : ''" />
                    </div>
                    <p v-if="otpIsRequired" class="otp-error">
                        OTP field is required
                    </p>
                    <div class="col-12 mt-3">
                        <ValidationProvider name="new-password" rules="required|max:15|min:8" vid="confirmation"
                            v-slot="{ errors, classes }">
                            <b-form-group label="" label-for="new-password" style="position: relative;">
                                <b-form-input id="new-password" :type="passwordType ? 'password' : 'text'" size="sm"
                                    placeholder="Enter new password" v-model="newPassword" :class="classes"
                                    style="background-color: var(--neutral-soft-grey); border: 0;"></b-form-input>
                                <img @click="viewPassword" style="position: absolute;top: 30%;right: 15px;"
                                    src="@/assets/zaajira-candidate/assets/icons/eye.svg" alt="" srcset="">
                            </b-form-group>
                            <div class="error-msg" :class="classes">
                                <span>{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider rules="required|confirmed:confirmation" name="confirmPassword"
                            v-slot="{ errors, classes }">
                            <b-form-group label="" label-for="confirm-password" style="position: relative;">
                                <b-form-input id="confirm-password" :type="passwordType ? 'password' : 'text'" size="sm"
                                    placeholder="Re-enter new password" v-model="confirmPassword" :class="classes"
                                    autocomplete="off"
                                    style="background-color: var(--neutral-soft-grey); border: 0;"></b-form-input>
                                <img @click="viewPassword" style="position: absolute;top: 30%;right: 15px;"
                                    src="@/assets/zaajira-candidate/assets/icons/eye.svg" alt="" srcset="">
                            </b-form-group>
                            <div class="error-msg" :class="classes">
                                <span>{{ errors[0] }}</span>
                                <!-- <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span> -->
                            </div>
                        </ValidationProvider>
                    </div>
                    <!-- <div class="col-12 mt-4 btn-flex-fd-row-jc-sb">
                    <span style="cursor: pointer;display:flex;align-items:center;color:#000; opacity: .8;"
                        @click.prevent.stop="goBack">Back</span>
                    <b-button variant="primary" size="sm" @click.prevent.stop="verifyOtp">Verify</b-button>
                </div> -->
                    <div class="col-12 my-4 btn-flex-fd-row-jc-sb">
                        <span @click="goBack" class="go-back"
                            style="cursor: pointer; display:flex;align-items:center;color:#9C9CA4;">Back</span>
                        <b-button variant="primary" size="sm" @click.prevent.stop="verifyOtp">Update password</b-button>
                    </div>
                    <div class="col-12 text-center my-4">
                        <span>Haven’t received the OTP? &nbsp;</span>
                        <span v-if="disableResend" class="resend-otp" @click.prevent.stop="resendOtp"> Resend</span>
                        <span v-else class="resend-otp"> {{ countDown }}</span>
                        <!-- <b-button v-if="!otp" size="sm" :disabled="disableResend" class="main__login--button p-3 mx-auto"
                    @click.prevent.stop="resendOtp">Resend&nbsp;{{ disableResend ? `in ${countDown}` : "" }}</b-button> -->
                    </div>
                </ValidationObserver>
            </b-form>
        </b-row>

    </b-row>
</template>

<script>


export default {
    name: "UpdatePassword",
    components: {},
    props: {
        isForgetPassword: {
            type: Boolean,
            default: true
        },
        otpPayload: {
            type: Object,
        },
    },
    data() {
        return {
            otpDigits: ['', '', '', ''],
            newPassword: null,
            confirmPassword: null,
            countDown: 120,
            disableResend: true,
            otpIsRequired: false,
            passwordType: true,
        }
    },
    methods: {
        handleKeyDown(event, index) {
            // console.log('Key Pressed:', event);
            // console.log('Current Index:', index);
            if (event.key === 'Backspace' && index > 0 && !this.otpDigits[index]) {
                // If Backspace is pressed and the current box is empty
                // Clear the previous box
                this.otpDigits[index - 1] = '';
                // Focus on the previous box
                this.$refs.OTPPassRef[index - 1].focus();
            } else if (event.key.match(/[0-9]/) && index < 3) {
                // } else if (event.target.tabIndex.toString().match(/[0-9]/) && index < 3 && this.otpDigits[index] !== '') {
                this.$refs.OTPPassRef[index + 1].focus();
            }
            this.otpPayload.otp = this.otpDigits
            this.$emit('update-otp-digits', this.otpDigits);
        },
        viewPassword() {
            this.passwordType = !this.passwordType
        },
        goBack() {
            console.log('back');
            this.$emit('goBack', this.isForgetPassword);
        },
        CheckLengthOfOTP(otpDigits) {
            const otp = otpDigits.filter(digit => digit !== '');
            // console.log(otp.length);
            return otp.length === 4
        },
        verifyOtp() {
            if (!this.CheckLengthOfOTP(this.otpDigits)) {
                this.otpIsRequired = true
                return
            } else {
                this.otpIsRequired = false
            }
            this.$refs.updatePassword.validate().then((result) => {
                console.log("updatePassword");
                console.log(result);
                if (result) {
                    const payload = {
                        "otp": this.otpDigits,
                        "password": this.newPassword
                    }
                    this.$emit('resetPassword', payload);
                }
            })
            // console.log('this.otpDigits.length', this.CheckLengthOfOTP(this.otpDigits));

        },
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1;
                    this.countDownTimer();
                }, 1000);
            } else {
                this.disableResend = true;
            }
        },
        resendOtp() {
            this.disableResend = false;
            this.countDown = 120;
            this.countDownTimer();
            this.$emit('resendOtp', { countDown: this.countDown, disableResend: this.disableResend });
        }
    },
    watch: {
        newPassword(newValue) {
            this.otpPayload.newPassword = newValue;
        },
        confirmPassword(newValue) {
            this.otpPayload.confirmPassword = newValue;
        },
    }

}
</script>

<style>
.otp-input1 {
    width: 100%;
    display: flex;
    padding-inline: .8rem .8rem;
    justify-content: space-between;
}

.otp-box1 {
    display: flex;
    width: 4rem;
    height: 4rem;
    gap: 10px;
    flex-shrink: 0;
    text-align: center;
    background: var(--neutral-soft-grey);
    border: none;
    border-radius: 10px;
}

@media screen and (max-width:352px) {

    .otp-box1 {
        width: calc(4rem - 1.5vw);
        height: calc(4rem - 1vh);
    }
}

.resend-otp {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}

.otp-error {
    color: #ff4b4b;
    font-family: "pn-regular";
    padding-inline: 1rem;
    display: inherit;
    margin-top: -15px;
    font-size: 13px;
}
</style>