<template>
    <CommonLayoutsVue>
        <template v-slot:child>
            <b-row v-if="!showOtp && !isForgetPassword" class="login-container">
                <b-row class="mt-4">
                    <b-col class="l-header-container">
                        <h2 class="login-header">{{ $gettext("Welcome_to_Zaajira") }}</h2>
                    </b-col>
                </b-row>
                <div class="login-content">
                    <ValidationObserver ref="login">
                        <b-form class="customized-form py-4" style="width: 100%">
                            <b-row class="">
                                <!-- <div class="col-lg-12 mb-3"> -->
                                <b-col cols="12" class="col-lg-12 mb-3">
                                    <CustomDropdown :selectedOption="usertype" :options="usertypeOptions"
                                        @update:selectedOption="updateUserType"></CustomDropdown>
                                </b-col>

                                <div class="col-12" v-if="usertype === 'Email'">
                                    <ValidationProvider name="email" rules="required|Email"
                                        v-slot="{ errors, classes }">
                                        <b-form-group label="" label-for="email">
                                            <b-form-input :class="classes" id="email" size="sm" type="email"
                                                v-model="email" placeholder="Email Address"></b-form-input>
                                        </b-form-group>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12" v-else>
                                    <ValidationProvider name="phone" rules="required|Phone"
                                        v-slot="{ errors, classes }">
                                        <b-form-group label="" label-for="phone">
                                            <b-form-input :class="classes" id="phone" size="sm" type="tel"
                                                v-model="mobile" placeholder="Mobile number"></b-form-input>
                                        </b-form-group>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] ? "Mobile number (eg. 256123456789 or +256123456789)" :
                                                ''
                                                }}</span>
                                            <!-- <span>{{ errors[0] }}</span> -->
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12" v-if="usertype === 'Email'">
                                    <ValidationProvider name="user-password" rules="required|max:15|min:8"
                                        vid="confirmation" v-slot="{ errors, classes }">
                                        <b-form-group label="" label-for="user-password">
                                            <b-form-input :class="classes" id="user-password" v-model="password"
                                                autocomplete="off" :type="passwordType ? 'password' : 'text'" size="sm"
                                                placeholder="Input your password"></b-form-input>
                                            <img @click="viewPassword"
                                                style="cursor: pointer;position: absolute;top: 25%;right: 30px;"
                                                src="@/assets/zaajira-candidate/assets/icons/eye.svg" alt="" srcset="">
                                        </b-form-group>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div v-if="usertype === 'Email'" class="px-3">
                                    <!-- Remember Me :: start -->
                                    <input style="accent-color: #2d68b2" value="remember_Me" id="remember_Me"
                                        type="checkbox" v-model="remember_Me" @change="rememberMe" />
                                    <label class="mx-2" for="checkbox">{{ $gettext("Remember_me") }}</label>
                                    <!-- Remember Me:: end -->
                                </div>
                                <div class="col-12 px-3">
                                    <vue-recaptcha :size="recaptchaSize" :sitekey="reCaptchaSiteKey"
                                        @verify="captchaVerify" @expired="captchaExpired" @error="captchaError"
                                        ref="recaptcha-login" class="d-flex justify-content-center mx-auto"
                                        style="overflow: hidden"></vue-recaptcha>
                                </div>


                                <!-- <b-form-checkbox class="mb-3">Remember me</b-form-checkbox> -->
                                <div class="col-12 mt-3 login-btn">
                                    <span style=" cursor: pointer;color:#9C9CA4;" @click="ForgetPassword">
                                        {{ $gettext("Forgot_password") }}
                                    </span>
                                    <b-button variant="primary" size="sm" @click.prevent.stop="ValidateInput">{{
                                        $gettext("Login") }}</b-button>
                                </div>
                                <div class="col-12 text-center">
                                    <span>{{ $gettext("New_User") }}</span>
                                    <router-link to="candidate-register">&nbsp;{{ $gettext("Register") }}</router-link>
                                </div>
                            </b-row>

                        </b-form>
                    </ValidationObserver>

                </div>
            </b-row>

            <OTPScreen v-if="showOtp" :otpDigits="otpDigits" @resendOtp="sendOtp" @goBack="showOtp = false"
                @verify="handleOTPVerification" @update-otp-digits="updateOTPDigits" />

            <UpdatePassword v-if="isForgetPassword" :otpPayload="otpPayload" @resendOtp="ResendOtpForgetPass"
                @goBack="isForgetPassword = false" @resetPassword="resetPassword" />
        </template>
    </CommonLayoutsVue>
</template>

<script>

import CommonLayoutsVue from '../../../components/Layouts/Common-Layouts.vue'
import CustomDropdown from '../../../components/Dropdown/Dropdown.vue'
import OTPScreen from '../../../components/Otp-Screen/Otp-screen.vue'
import UpdatePassword from '../../../components/update-password/Update-Password.vue'
import Multiselect from 'vue-multiselect';
import VueRecaptcha from "vue-recaptcha";
import { socialLoginClientId, reCaptchaSiteKey, FRONTEND_BASE_URL } from "@/config";
import CandidateService from "@/apiServices/CandidateService";

export default {
    name: "Login",
    components: { CommonLayoutsVue, OTPScreen, UpdatePassword, CustomDropdown, Multiselect, VueRecaptcha },
    data() {
        return {
            userRole: 3,
            usertype: "Email",
            usertypeOptions: [
                "Email",
                "Phone",
            ],
            remember_Me: false,
            dropdownOption: false,
            email: "",
            mobile: '',
            password: '',
            passwordType: true,
            otpDigits: ['', '', '', ''],
            showOtp: false,
            isForgetPassword: false,
            otpPayload: {},
            captchaValue: null,
            reCaptchaSiteKey: reCaptchaSiteKey,
            recaptchaSize: 'normal',
            isMobileMenu: window.innerWidth <= 367
        }
    },
    methods: {
        resetRemember() {
            this.$cookies.set("rusername", "", "-1");
            this.$cookies.set("pswd", "", "-1");
            this.remember_Me = false;
        },
        setRemember() {
            if (this.remember_Me) {
                this.$cookies.set("rusername", this.usertype === 'Email' ? this.email : this.mobile);
                this.$cookies.set("pswd", this.password);
                console.log("setRemember", this.remember_Me, this.mobile, this.password);
            } else {
                this.resetRemember();
            }
        },
        getRemember() {
            if (this.usertype === "Email") {
                if (this.$cookies.get("rusername") !== null) {
                    this.email = this.$cookies.get("rusername");
                    this.password = this.$cookies.get("pswd");
                    this.remember_Me = true;
                    // console.log("1st if", this.remember_Me, this.email, this.password);
                }
                else {
                    // console.log("this.usertype", this.usertype);
                    this.email = ''
                    this.password = ''
                    this.remember_Me = false;
                }
            } else {
                this.mobile = ''
            }

        },
        rememberMe() {
            if (this.remember_Me === true) {
                this.setRemember();
            } else {
                this.resetRemember();
            }
        },
        updateUserType(usertype) {
            this.usertype = usertype;
        },

        viewPassword() {
            this.passwordType = !this.passwordType
        },
        ForgetPassword() {
            if (this.email !== '') {
                const payload = { user_email: this.email }
                console.log("payload", payload);
                CandidateService.forgotPassword(payload)
                    .then((res) => {
                        console.log("res", res);
                        if (res.data.status && res.data.data === "Email sent successfully") {
                            this.$toasted.show(`An OTP has been sent to your registered email`, {
                                duration: 6000,
                                type: "success",
                                icon: "check",
                            });
                            this.isForgetPassword = true

                        } else {

                        }
                    })
                    .catch((error) => {
                        this.$toasted.show(`Email is not registered!`, { duration: 6000 });
                        console.log(error);
                    })
            } else {
                this.$toasted.show(`Email is required!`, { duration: 6000 });
            }
        },
        resetPassword(payload) {
            // console.log('this.otpPayload', this.otpPayload);
            payload.user_email = this.email
            payload.otp = payload.otp[0].toString() + payload.otp[1].toString() + payload.otp[2].toString() + payload.otp[3].toString(),
                console.log('Payload', payload);
            CandidateService.resetPassword(payload)
                .then((res) => {
                    console.log('res', res);
                    if (res.data.data.status) {
                        this.$toasted.show(`${res.data.data.msg}`, {
                            duration: 6000,
                            type: "success",
                            icon: "check",
                        });
                    } else {
                        this.$toasted.show(`OTP is invalid`, { duration: 6000 });
                    }
                })
                .catch((error) => {
                    this.$toasted.show(`OTP is invalid`, { duration: 6000 });
                    console.log('error', error);
                })
        },
        removePlus(mobileNumber) {
            return mobileNumber.replace(/\+/g, '');
        },
        Login() {
            if (this.userRole === 3 && this.usertype === 'Phone') {
                const payload = {
                    // mobile_number: '+919691060747' //this.mobile
                    mobile_number: "+" + this.removePlus(this.mobile)
                }
                let loader = this.$loading.show();
                CandidateService.generateOTP(payload)
                    .then((res) => {
                        if (res.data.data.notFound) {
                            this.$refs["recaptcha-1"] && this.$refs["recaptcha-1"].reset();
                            this.$router.push({ name: 'Candidate-login' })
                            this.$toasted.show(`${res.data.data.msg}`, { duration: 6000 });
                            loader.hide();
                        } else {
                            console.log("SMS sent successfully", res);
                            this.showOtp = true;
                            this.$toasted.show(`${res.data.data}`, {
                                duration: 6000,
                                type: "success",
                                icon: "check",
                            });
                            loader.hide();
                        }

                    })
                    .catch((err) => {
                        console.log("[Backend] Verify email error: ", err);
                        loader.hide();
                        this.$toasted.show("Server Error", {
                            duration: 6000,
                            icon: "clear",
                            type: "error",
                        });
                    });
            }
            else {
                const payload = {
                    email: this.email ?? '',
                    password: this.password,
                    mobile_number: '',
                    role_id: this.userRole,

                }
                let loader = this.$loading.show();
                CandidateService.Login(payload)
                    .then(async (res) => {
                        console.log("Login With Email::", res);
                        if (res.data.data.notFound) {
                            this.$refs["recaptcha-1"] && this.$refs["recaptcha-1"].reset();
                            // this.$router.push({ name: 'Candidate-login' })
                            this.$toasted.show(`${res.data.data.msg}`, { duration: 6000 });
                            loader.hide();
                            return
                        } else {
                            if (res.data.data.invalidPass) {
                                this.$toasted.show(`${res.data.data.msg}`, { duration: 6000 });
                                loader.hide();
                                return
                            }
                            else {

                                console.log("Login::", res);

                                this.$toasted.show(`${res.data.message}`, {
                                    duration: 6000,
                                    type: "success",
                                    icon: "check",
                                });
                                loader.hide();
                                await this.SetLoginCredentialAndNavigate(res)

                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }

            // const payload = {
            //     userRole: this.userRole,
            //     usertype: this.usertype,
            //     email: this.email,
            //     phone: this.mobile,
            //     isLoggedIn: true
            // }
            // if (payload.userRole == 3) {
            //     this.$store.dispatch("onSetUserSigninValues", payload);
            //     this.$router.push({ path: '/candidate/user-profile' })
            // }
        },
        ValidateInput() {
            this.$refs.login.validate().then((result) => {
                console.log("aman");
                console.log(result);
                if (result) {
                    if (this.captchaValue) {
                        this.Login();
                    } else {
                        this.$toasted.show("Please verify captcha", {
                            duration: 6000,
                            type: "error",
                            icon: "clear",
                        });
                    }
                    return;
                } else {
                    return;
                }
            });
        },
        updateOTPDigits(updatedDigits) {
            // Handle the updated OTP digits received from the child component
            this.otpDigits = updatedDigits;
        },
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1;
                    this.countDownTimer();
                }, 1000);
            } else {
                this.disableResend = false;
            }
        },
        sendOtp(countDownOrDisableResend) {
            // this.$toasted.show(`Otp has been sent!`, { duration: 6000 });
            // console.log('countDownOrDisableResend', countDownOrDisableResend);

            const payload = {
                // mobile_number: '+919691060747' //this.mobile
                mobile_number: "+" + this.removePlus(this.mobile)
            }
            let loader = this.$loading.show();
            CandidateService.generateOTP(payload)
                .then((res) => {
                    if (res.data.data.notFound) {
                        this.$refs["recaptcha-1"] && this.$refs["recaptcha-1"].reset();
                        this.$router.push({ name: 'Candidate-login' })
                        this.$toasted.show(`${res.data.data.msg}`, { duration: 6000 });
                        loader.hide();
                    } else {
                        console.log("SMS sent successfully", res);
                        this.showOtp = true;
                        this.$toasted.show(`${res.data.data}`, {
                            duration: 6000,
                            type: "success",
                            icon: "check",
                        });
                        loader.hide();
                    }

                })
                .catch((err) => {
                    console.log("[Backend] Verify email error: ", err);
                    loader.hide();
                    this.$toasted.show("Server Error", {
                        duration: 6000,
                        icon: "clear",
                        type: "error",
                    });
                });

        },
        ResendOtpForgetPass(countDownOrDisableResend) {
            // this.$toasted.show(`Otp has been sent!`, { duration: 6000 });
            // console.log('countDownOrDisableResend', countDownOrDisableResend);
            this.ForgetPassword()

        },
        handleOTPVerification(OTP) {
            // Handle OTP verification logic here
            console.log('this.OTPdigits', this.otpDigits);
            console.log('OTP Verified!', OTP);

            const payload = {
                // mobile_number: '+919691060747',//this.mobile
                mobile_number: "+" + this.removePlus(this.mobile),
                otp:
                    OTP[0].toString() +
                    OTP[1].toString() +
                    OTP[2].toString() +
                    OTP[3].toString(),
            }
            console.log('VerifyOTP', payload);
            CandidateService.verifyOTP(payload)
                .then(async (response) => {
                    console.log("Verify OTP response: ", response);

                    if (response.data.message === "Login successful") {
                        console.log("Login::Success", response.data);
                        this.$toasted.show(`${response.data.message}`, {
                            duration: 6000,
                            type: "success",
                            icon: "check",
                        });

                        this.SetLoginCredentialAndNavigate(response)

                    }
                    else {
                        console.log("Login::Invalid OTP!", response.data);
                        this.$toasted.show(`${response.data.data.msg}`, {
                            duration: 6000,
                            type: "error",
                            icon: "clear",
                        });
                        return
                    }
                })
                .catch((err) => {
                    console.log("[Backend] Verify email error: ", err);
                    this.loader.hide();
                    this.$toasted.show("Registration Failed!", {
                        duration: 6000,
                        type: "error",
                        icon: "clear",
                    });
                });
        },
        async SetLoginCredentialAndNavigate(response) {
            delete response.data.data.user.user_password
            const payload = {
                userData: response.data.data.user,
                userName: response.data.data.user.user_username,
                userEmail: response.data.data.user.user_email,
                userId: response.data.data.user.user_id,
                userRole: response.data.data.user.role_id,
                userToken: response.data.data.token,
                isLoggedIn: true
            };

            console.log("THIS PERSON IS CANDIDATE");
            console.log(payload);
            if (payload.userRole === 3) {
                this.$store.dispatch("onSetUserSigninValues", payload);
            }
            // set cookies
            this.$cookies.set("username", payload.userName);
            this.$cookies.set("AIAPADHASHID", payload.userToken);
            this.$cookies.set("user_id", payload.userId);
            this.$cookies.set("role_id", payload.userRole);
            console.log("now checking if user is first login ?");
            if (response.data.data.user.first_login == "Y") {
                try {
                    console.log("This is the first time user logging so set the login flag");
                    let setLoginFlagResponse = await CandidateService.SetLoginFlag();
                    console.log("SetLoginFlag Response : ", setLoginFlagResponse);
                    this.$router.push({ path: '/candidate/create-visiting-card' })
                } catch (error) {
                    console.log(error.message);
                }
            } else {
                console.log("Not the first time login");
                this.$router.push({ path: '/' })
            }
        },


        captchaVerify(event) {
            this.rememberMe();
            console.log(`Captcha Verify Event :`, event);
            this.captchaValue = event;
        },
        captchaExpired() {
            console.log("Captcha Expired");
            this.captchaValue = null;
        },
        captchaError() {
            console.log("Captcha Error");
            this.captchaValue = null;
        },
    },
    beforeMount() {
        this.recaptchaSize = this.isMobileMenu ? 'compact' : 'normal'

        this.getRemember()
    },

    computed: {
    }

}
</script>

<style>
.login-container {
    max-width: 500px;
    width: 100%;
    display: flex;
    border-radius: 10px;
    background: var(--neutral-white, #FFF);
    box-shadow: 0px 50px 100px 0px rgba(0, 0, 0, 0.10), 100px 100px 100px 100px rgba(0, 0, 0, 0.10);
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    /* max-height: 600px; */
    /* min-height: 100%; */
    /* bottom: 9%; */
    /* height: 100%; */
    /* min-height: 65%; */
    /* font-family: Gilroy-Bold; */
}



.login-header {
    color: var(--neutral-black, #141522);
    text-align: center;
    /* font-family: Gilroy-Bold; */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 36px */
}

.login-content {
    width: 100%;
}

.btn-secondary {
    color: #000;
    background-color: #A4A4A4;
    ;
    border-color: #A4A4A4;
}

.login-type-dropdown {
    width: 92%;
}

.login-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>